import { defineStore } from 'pinia';
import { getAuthenticatedHeaders } from '../utils/auth';
import getPath from '@/utils/getPath';
import { apiPut, apiGet, apiPost } from '@/utils/api';

export const DAILY_GOAL_STREAK_USER_METRIC_TYPE_ID = 1;
export const DAILY_GOAL_USER_METRIC_TYPE_ID = 2;
export const DAILY_COMPLETE_USER_METRIC_TYPE_ID = 3;
export const WEEKLY_COMPLETE_USER_METRIC_TYPE_ID = 4;
export const MONTHLY_COMPLETE_USER_METRIC_TYPE_ID = 5;
export const DAILY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID = 6;
export const WEEKLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID = 7;
export const MONTHLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID = 8;
export const DAILY_WIP_USER_METRIC_TYPE_ID = 9;
export const WEEKLY_WIP_USER_METRIC_TYPE_ID = 10;
export const MONTHLY_WIP_USER_METRIC_TYPE_ID = 11;
export const DAILY_ASSIGNED_USER_METRIC_TYPE_ID = 12;
export const WEEKLY_ASSIGNED_USER_METRIC_TYPE_ID = 13;
export const MONTHLY_ASSIGNED_USER_METRIC_TYPE_ID = 14;

export const useMetrics = defineStore('metrics', {
  state: () => ({
    // Generic State
    isLoading: true,
    isLoadingSelectedUser: true,
    currentTabID: null,
    showMetrics: false,

    // Active User (Logged in User)
    activeUsername: null,
    activeUserID: null,
    activeUserDailyGoalStreak: 0,
    activeUserDailyGoal: 0,
    activeUserDailyComplete: 0,
    activeUserWeeklyComplete: 0,
    activeUserMonthlyComplete: 0,
    activeUserDailyTimeToComplete: 0,
    activeUserWeeklyTimeToComplete: 0,
    activeUserMonthlyTimeToComplete: 0,
    activeUserDailyWip: 0,
    activeUserWeeklyWip: 0,
    activeUserMonthlyWip: 0,
    activeUserDailyAssigned: 0,
    activeUserWeeklyAssigned: 0,
    activeUserMonthlyAssigned: 0,

    // Selected User (Selected from Users Table)
    selectedUserDailyGoalStreak: 0,
    selectedUserDailyGoal: 0,
    selectedUserDailyComplete: 0,
    selectedUserWeeklyComplete: 0,
    selectedUserMonthlyComplete: 0,
    selectedUserDailyTimeToComplete: 0,
    selectedUserWeeklyTimeToComplete: 0,
    selectedUserMonthlyTimeToComplete: 0,
    selectedUserDailyWip: 0,
    selectedUserWeeklyWip: 0,
    selectedUserMonthlyWip: 0,
    selectedUserDailyAssigned: 0,
    selectedUserWeeklyAssigned: 0,
    selectedUserMonthlyAssigned: 0,
    metricsHandler: {
      [DAILY_GOAL_STREAK_USER_METRIC_TYPE_ID]: 'DailyGoalStreak',
      [DAILY_GOAL_USER_METRIC_TYPE_ID]: 'DailyGoal',
      [DAILY_COMPLETE_USER_METRIC_TYPE_ID]: 'DailyComplete',
      [WEEKLY_COMPLETE_USER_METRIC_TYPE_ID]: 'WeeklyComplete',
      [MONTHLY_COMPLETE_USER_METRIC_TYPE_ID]: 'MonthlyComplete',
      [DAILY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID]: 'DailyTimeToComplete',
      [WEEKLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID]: 'WeeklyTimeToComplete',
      [MONTHLY_TIME_TO_COMPLETE_USER_METRIC_TYPE_ID]: 'MonthlyTimeToComplete',
      [DAILY_WIP_USER_METRIC_TYPE_ID]: 'DailyWip',
      [WEEKLY_WIP_USER_METRIC_TYPE_ID]: 'WeeklyWip',
      [MONTHLY_WIP_USER_METRIC_TYPE_ID]: 'MonthlyWip',
      [DAILY_ASSIGNED_USER_METRIC_TYPE_ID]: 'DailyAssigned',
      [WEEKLY_ASSIGNED_USER_METRIC_TYPE_ID]: 'WeeklyAssigned',
      [MONTHLY_ASSIGNED_USER_METRIC_TYPE_ID]: 'MonthlyAssigned',
    },
  }),
  actions: {
    setShowMetrics(val) {
      this.showMetrics = val;
    },
    getGoal(activeUser = false, goal) {
      return activeUser
        ? this[`activeUser${goal}`]
        : this[`selectedUser${goal}`];
    },
    async publishEvent(detailType, detail) {
      const headers = await getAuthenticatedHeaders();

      const payload = {
        detailType,
        detail,
      };

      this.isLoading = true;
      try {
        const response = await fetch(
          getPath(
            'metrics/events',
            process.env.VUE_APP_INSURANCE_API_GATEWAY_ENDPOINT
          ),
          {
            method: 'POST',
            headers: headers.headers,
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async setTimeSpent(auxo_user_id, work_item_id, timespent_ms) {
      try {
        const body = {
          auxo_user_id,
          work_item_id,
          time_spent: Number(timespent_ms),
        };

        await apiPost(getPath('metrics/workItem/timeSpent'), body);
      } catch (error) {
        console.error(error);
      }
    },
    async getUserMetrics() {
      //Get and Set metrics for the active user logged in.  This is used by side navigation goal streak component.

      const auxo_user_id = this.activeUserID;
      if (!auxo_user_id) {
        return;
      }

      this.isLoading = true;

      try {
        this.isLoading = true;
        let allMetrics = await this.callUserMetricsApi(auxo_user_id);

        allMetrics.forEach(metric => {
          const { user_metric_type_id, value } = metric;
          const metricKey = this.metricsHandler[user_metric_type_id];

          if (metricKey) {
            const field = `activeUser${metricKey}`;
            this[field] = value;
          }
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async getSelectedUserMetrics(auxo_user_id) {
      //Get and Set metrics for a user.  This method is used by the UserDetail view.

      if (!auxo_user_id) {
        console.warn('No selected user ID provided.');
        return;
      }

      this.selectedUserDailyGoalStreak = 0;
      this.selectedUserDailyGoal = 0;
      this.selectedUserDailyComplete = 0;
      this.selectedUserWeeklyComplete = 0;
      this.selectedUserMonthlyComplete = 0;
      this.selectedUserDailyTimeToComplete = 0;
      this.selectedUserWeeklyTimeToComplete = 0;
      this.selectedUserMonthlyTimeToComplete = 0;
      this.selectedUserDailyWip = 0;
      this.selectedUserWeeklyWip = 0;
      this.selectedUserMonthlyWip = 0;
      this.selectedUserDailyAssigned = 0;
      this.selectedUserWeeklyAssigned = 0;
      this.selectedUserMonthlyAssigned = 0;

      try {
        this.isLoadingSelectedUser = true;
        let allMetrics = await this.callUserMetricsApi(auxo_user_id);

        allMetrics.forEach(metric => {
          const { user_metric_type_id, value } = metric;
          const metricKey = this.metricsHandler[user_metric_type_id];

          if (metricKey) {
            const field = `selectedUser${metricKey}`;
            this[field] = value;
          }
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingSelectedUser = false;
      }
    },
    async callUserMetricsApi(auxo_user_id) {
      try {
        const queryStringParameter = new URLSearchParams({
          userId: auxo_user_id,
        });
        const response = await apiGet(getPath('metrics'), queryStringParameter);

        if (response.data.length == 0) return null;

        const {
          daily_goal: dailyGoal,
          daily_metrics: dailyMetrics,
          weekly_metrics: weeklyMetrics,
          monthly_metrics: monthlyMetrics,
        } = response.data;

        const allMetrics = [
          ...dailyGoal,
          ...dailyMetrics,
          ...weeklyMetrics,
          ...monthlyMetrics,
        ];

        return allMetrics;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async setUserGoal(auxo_user_id, goal) {
      this.isLoading = true;
      try {
        const headers = await getAuthenticatedHeaders();

        const payload = { value: goal };

        const response = await apiPut(
          getPath(`users/${auxo_user_id}/goal`),
          payload,
          headers
        );

        this.activeUserID == auxo_user_id
          ? (this.activeUserDailyGoal = response?.data?.data?.data)
          : (this.selectedUserDailyGoal = response?.data?.data?.data);

        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
  },
});
